
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  watchEffect,
  onUnmounted,
} from "vue";
import { useI18n } from "vue-i18n";
import QrGenerator from "@/components/qr-generator/QrGenerator.vue";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "show-qr-code",
  components: {
    QrGenerator,
  },
   emit: ["login"],
  props: {
    qr: {
      type: String,
      default: "/media/qrcode.png",
    },
    serverStatus: {
      type: String,
      default: "",
    },
    canLoginToWhatsAppWithCode: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const route = useRoute();
    const loading = ref(true);
    const qrData = ref();

    const counter = ref(100);
    const percent = computed(() => {
      return Math.floor((counter.value / 100) * 100);
    });
    const format = () => {
      return percent.value === 100
        ? ""
        : `${percent.value} ` + translate("second");
    };

    const zeroCount = ref(0);
    const timer = ref();

    const decreaseCounter = () => {
      counter.value -= 1;
      if (counter.value === 0) {
        counter.value = 100;
        zeroCount.value += 1;
        if (zeroCount.value === 2) {
          sendAlertToAdmin();
        } else {
          counter.value = 100;
        }
      }
    };


    const stopTimer = () => {
      clearInterval(timer.value);
    };

    const resetTimer = () => {
      counter.value = 100;
      zeroCount.value = 0;
      timer.value = setInterval(decreaseCounter, 1000);
    };

    onMounted(() => {
      resetTimer();
    });

    const sendAlertToAdmin = () => {
      // send alert message to admin
      ApiService.post(`log/save`, {
        type: "qr_not_sent",
        payload: {
          bot_id: route.params.bot_id,
          info: translate(
            "The QR code for the user could not be uploaded Check out"
          ),
        },
      });
    };

    watchEffect(() => {
      if (props.qr) {
        resetTimer();
        stopTimer();
        qrData.value = props.qr;
        loading.value = false;
      }
    });

    onUnmounted(() => {
      stopTimer();
    });

    return {
      translate,
      loading,
      qrData,

      counter,
      zeroCount,
      percent,
      format,
      // server_status,
    };
  },
});
