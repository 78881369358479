
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";

import { useRoute } from "vue-router";
import { checkPackagePlatform } from "@/core/services/HelpersFun";
import { useI18n } from "vue-i18n";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { platforms } from "@/store/pinia/Platforms";
import serverInfoTooltip from "@/components/serverInfoTooltip.vue";
import { storeToRefs } from "pinia";
import { AllBots } from "@/store/pinia/AllBots";
import LoginWithCodeInWhatsapp from "@/views/pages/SubscriptionConnections/component/connect/LoginWithCodeInWhatsapp.vue";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import Alert from "@/components/Alert.vue";
import ShowQRCode from "@/views/pages/SubscriptionConnections/component/connect/ShowQRCode.vue";
import QrCodeSetup from "@/views/pages/SubscriptionConnections/component/connect/QrCodeSetup.vue";
import SuccessConnectMessage from "@/components/SuccessConnectMessage.vue";
import PhoneInput from "@/components/Phone/PhoneInput.vue";
import Code from "@/components/Phone/Code.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ElNotification } from "element-plus/es";
import Timer from "@/components/Timer.vue";
import { ElMessage } from "element-plus";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "connect-new-way-to-platforms",
  components: {
    Alert,
    ShowQRCode,
    QrCodeSetup,
    SuccessConnectMessage,
    PhoneInput,
    Code,
    Timer,
    LoginWithCodeInWhatsapp,
    serverInfoTooltip,
  },
  props: {
    botId: {
      type: String,
      default: "",
    },
    platform: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const botIdHolder = computed(() => {
      if (props.botId && props.botId !== "") return props.botId;
      return route.params.bot_id;
    });
    var timer;
    var creating_timer;
    const defaultPlatform = computed(() => {
      if (route.params.platform) return route.params.platform ?? "telegram";
      else if (props.platform) return props.platform ?? "telegram";
      else return "telegram";
    });

    const timerHandler = reactive({
      whatsapp: true,
      telegram: true,
      bale: true,
      eitaa: true,
      rubika: true,
    });

    const changeServerDialog = ref(false);
    const machines = ref();
    const select_machine = ref();

    const open_change_server = () => {
      changeServerDialog.value = true;

      ApiService.get("admin/machine/all?behzad=1")
        .then((data) => {
          machines.value = data.data.data.machines.filter((item) => {
            return item.platforms.includes(targetPlatform.value.key);
          });

          console.log(machines.value);
          console.log(targetPlatform.value.key);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const change_server = () => {
      // changeServerDialog.value = true;

      ApiService.post("admin/bot/change-container-machine", {
        bot_id: botIdHolder.value,
        platform: targetPlatform.value.key,
        machine_id: select_machine.value,
      })
        .then((data) => {
          changeServerDialog.value = false;
          console.log(data);

          Swal.fire({
            text: data.data.message,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: translate("ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    // platforms
    const platformsHolder = platforms();
    const { platformsList, targetPlatform } = storeToRefs(platformsHolder);
    const { setTargetPlatform } = platformsHolder;

    // bots
    const AllBotsHolder = AllBots();
    const { findBot, botList } = storeToRefs(AllBotsHolder);
    const { fetchBotList, setDefaultBotId, findTargetPlatformInBot } =
      AllBotsHolder;

    // const holder = reactive({
    //   qrData: "",
    //   step: "",
    //   name: "",
    // });125px

    const newHolder = reactive({
      whatsapp: {
        qrData: "",
        step: "",
        name: "",
        phone_code: "",
        mobile: "",
        phone_data: "",
        code: "",
        password: "",
        loading: false,
      },
      whatsapp_vip: {
        qrData: "",
        step: "",
        name: "",
        phone_code: "",
        mobile: "",
        phone_data: "",
        code: "",
        password: "",
        loading: false,
      },
      telegram: {
        qrData: "",
        step: "",
        name: "",
        phone_code: "",
        mobile: "",
        phone_data: "",
        code: "",
        password: "",
        loading: false,
      },
      bale: {
        qrData: "",
        step: "",
        name: "",
        first_name: "",
        last_name: "",
        phone_code: "",
        mobile: "",
        phone_data: "",
        code: "",
        password: "",
        loading: false,
      },
      eitaa: {
        qrData: "",
        step: "",
        name: "",
        first_name: "",
        last_name: "",
        phone_code: "",
        mobile: "",
        phone_data: "",
        code: "",
        password: "",
        loading: false,
      },
      rubika: {
        qrData: "",
        step: "",
        name: "",
        first_name: "",
        last_name: "",
        phone_code: "",
        mobile: "",
        phone_data: "",
        code: "",
        password: "",
        loading: false,
      },
      canLoginToWhatsAppWithCode: true,
      otpWay: "qr", // qrCode | code
    });

    const text = ref("در حال ساخت سرور...");
    const text2 = ref(
      targetPlatform.value?.key === "whatsapp"
        ? "در حال ایجاد QR کد..."
        : "در حال ارسال کد"
    );
    const checkClass = ref("bi bi-check text-success d-none");
    const checkClass2 = ref("bi bi-check text-success d-none");
    const newClass = ref();
    const newClass2 = ref("box d-none");
    const newClass3 = ref();
    const newClass4 = ref("box d-none");
    // connect to socket
    const socketResponse = ref();
    const server_status = ref("");

    const CreatingtimerCount = ref(30);
    var creating_start_count;

    const qrtimerCount = ref(0);
    var qr_start_count;

    var start_count_account;

    // const connectToSocket = async () => {
    //   let userId = JwtService.getUserId();
    //   if (JwtService.getUserId()) {
    //     window.Echo.private("user." + userId)
    //       .listen(".creating_server", (e) => {
    //         // creating_start_count = setInterval(() => {
    //         //   CreatingtimerCount.value -= 1;
    //         //   if (CreatingtimerCount.value === 0) {
    //         //     login("");
    //         //     CreatingtimerCount.value = 30;
    //         //   }
    //         // }, 1000);
    //         socketResponse.value = e;
    //         if (e.bot_id === botIdHolder.value) {
    //           text2.value =
    //             targetPlatform.value?.key === "whatsapp"
    //               ? "در حال ایجاد QR کد..."
    //               : "در حال ارسال کد";
    //           newClass.value = "text-gray-800";
    //           newClass2.value = "box";
    //         }
    //       })
    //       .listen(".server_created", (e) => {
    //         // clearInterval(creating_start_count);
    //         // CreatingtimerCount.value = 0;

    //         socketResponse.value = e;
    //         if (e.bot_id === botIdHolder.value) {
    //           newClass.value = "text-gray-800 bg-light-success";
    //           checkClass.value = "bi bi-check text-success";
    //           text.value = "سرور ساخته شد ";
    //           newClass2.value = "box d-none";
    //           setTimeout(() => {
    //             newClass3.value = "text-gray-800";
    //             newClass4.value = "box";
    //           }, 800);
    //           if (
    //             targetPlatform.value?.key === "whatsapp" &&
    //             e.platform === targetPlatform.value?.key
    //           ) {
    //             login("created");
    //           } else {
    //             if (e.platform === targetPlatform.value?.key) {
    //               setTimeout(() => {
    //                 sendCode("build");
    //               }, 5000);
    //             }
    //           }
    //         }
    //       })
    //       .listen(".qr_data", (e) => {
    //         window.clearTimeout(timer);
    //         if (server_status.value === "building") {
    //           if (
    //             targetPlatform.value?.key === "whatsapp" &&
    //             e.botId === botIdHolder.value
    //           ) {
    //             newClass3.value = "text-gray-800 bg-light-success";
    //             checkClass2.value = "bi bi-check text-success";
    //             text2.value = "QR کد ساخته شد ";
    //             newClass4.value = "box d-none";
    //             setTimeout(() => {
    //               server_status.value = "enable";
    //             }, 1000);
    //           }
    //         }
    //         // }
    //         socketResponse.value = e;
    //         if (e.botId === botIdHolder.value && credentialWay.value === "qr") {
    //           newHolder[e.platform].qrData = e.qrData;
    //         }
    //       })
    //       .listen(".setup_bot", (e) => {
    //         window.clearTimeout(timer);
    //         socketResponse.value = e;
    //         if (e.botId === botIdHolder.value && credentialWay.value === "qr") {
    //           newHolder[e.platform].step = "setup_bot";
    //         }
    //       })
    //       .listen(".activation_code_sent", (e) => {
    //         window.clearTimeout(timer);
    //         if (e.bot.bot_id === botIdHolder.value) {
    //           if (targetPlatform.value?.key !== "whatsapp") {
    //             checkClass2.value = "bi bi-check text-success";
    //             text2.value = "کد ارسال شد";
    //             newClass4.value = "box d-none";
    //             socketResponse.value = e;
    //             newHolder[e.platform].step = "sendCode";
    //             disableLoading(e.platform);
    //           }
    //           setTimeout(() => {
    //             server_status.value = "enable";
    //           }, 1000);
    //         }
    //       })
    //       .listen(".activation_code_error", (e) => {
    //         window.clearTimeout(timer);
    //         socketResponse.value = e;
    //         if (e.botId === botIdHolder.value) {
    //           newHolder[e.platform].step = "need_to_login";
    //           // show Error
    //           disableLoading(e.platform);
    //         }
    //       })
    //       .listen(".otp_send_code_error", (e) => {
    //         window.clearTimeout(timer);
    //         socketResponse.value = e;
    //         if (e.botId === botIdHolder.value) {
    //           newHolder[e.platform].step = "need_to_login";
    //           // show Error
    //           disableLoading(e.platform);
    //         }
    //       })
    //       .listen(".loading_messages", (e) => {
    //         window.clearTimeout(timer);
    //         return;
    //       })
    //       .listen(".need_password", (e) => {
    //         window.clearTimeout(timer);
    //         socketResponse.value = e;
    //         if (e.botId === botIdHolder.value) {
    //           newHolder[e.platform].step = "password";
    //           disableLoading(e.platform);
    //         }
    //       })
    //       .listen(".otp_registered", (e) => {
    //         window.clearTimeout(timer);
    //         socketResponse.value = e;
    //         if (e.botId === botIdHolder.value) {
    //           fetchBotList();
    //           newHolder[e.platform].step = "ready";
    //           disableLoading(e.platform);
    //         }
    //       })
    //       .listen(".bot_activated", (e) => {
    //         window.clearTimeout(timer);
    //         socketResponse.value = e;
    //         if (e.botId === botIdHolder.value) {
    //           disableLoading(e.platform);
    //           fetchBotList();
    //           newHolder[e.platform].step = "ready";
    //         }
    //       })
    //       .listen(".otp_login_error", (e) => {
    //         window.clearTimeout(timer);
    //         socketResponse.value = e;
    //         if (e.botId === botIdHolder.value) {
    //           if (targetPlatform.value.key !== "bale") {
    //             newHolder[e.platform].step = "need_to_login";
    //           }
    //           disableLoading(e.platform);
    //         }
    //       })
    //       .listen(".otp_password_error", (e) => {
    //         window.clearTimeout(timer);
    //         socketResponse.value = e;
    //         if (e.botId === botIdHolder.value) {
    //           newHolder[e.platform].step = "need_to_login";
    //           disableLoading(e.platform);
    //         }
    //       })
    //       .listen(".otp_need_register_fields", (e) => {
    //         window.clearTimeout(timer);
    //         socketResponse.value = e;
    //         if (e.botId === botIdHolder.value) {
    //           newHolder[e.platform].step = "signup";
    //           disableLoading(e.platform);
    //         }
    //       })
    //       .listen(".bot_deactivated", (e) => {
    //         window.clearTimeout(timer);
    //         socketResponse.value = e;
    //         if (e.botId === botIdHolder.value) {
    //           newHolder[e.platform].step = "need_to_login";
    //           fetchBotList();
    //           if (credentialWay.value === "qr") {
    //             login("");
    //             newHolder[e.platform].qrData = "";
    //           }
    //           disableLoading(e.platform);
    //         }
    //       })
    //       .listen(".login_with_phone_activated", (e) => {
    //         window.clearTimeout(timer);
    //         console.log("check login_with_phone_activated");

    //         newHolder.canLoginToWhatsAppWithCode = true;
    //       })
    //       // .listen(".bot_session_removed", (e) => {
    //       //   alert(e);
    //       //   window.location.reload();
    //       // })
    //       .error((error) => {
    //         console.error(error);
    //       });
    //   }
    // };

    const loginWithCodeData = ref({});
    const delete_ids = ref();
    const pulling_data = ref();
    const pulling = () => {
      if (
        route.name === "connect-to-platform" ||
        route.name === "welcome-wizard"
      ) {
        console.log("pulling");
        ApiService.query("/whats-up", {
          params: { delete_ids: delete_ids.value },
        })
          .then((data) => {
            if (JSON.parse(pulling_data.value).polling_mode === true) {
              console.log(data.data.data);
              let res = data.data.data.operations;

              delete_ids.value = [];
              for (let i = 0; i < res.length; i++) {
                // delete_ids.value.push(res[i].id);

                if (res[i].type === "creating_server") {
                  delete_ids.value.push(res[i].id);
                  clearInterval(creating_start_count);

                  creating_start_count = setInterval(() => {
                    CreatingtimerCount.value = CreatingtimerCount.value - 1;
                    if (CreatingtimerCount.value === 0) {
                      // login("");
                      CreatingtimerCount.value = 30;
                    }
                  }, 1000);

                  socketResponse.value = res[i].payload;
                  if (res[i].payload.bot_id === botIdHolder.value) {
                    text2.value =
                      targetPlatform.value?.key === "whatsapp"
                        ? "در حال ایجاد QR کد..."
                        : "در حال ارسال کد";
                    newClass.value = "text-gray-800";
                    // newClass2.value = "box";
                  }
                }
                if (res[i].type === "server_created") {
                  delete_ids.value.push(res[i].id);
                  clearInterval(creating_start_count);
                  CreatingtimerCount.value = 0;

                  clearInterval(qr_start_count);
                  qrtimerCount.value = 180;
                  qr_start_count = setInterval(() => {
                    qrtimerCount.value = qrtimerCount.value - 1;
                    if (qrtimerCount.value === 0) {
                      // login("");
                      qrtimerCount.value = 180;
                    }
                  }, 1000);

                  socketResponse.value = res[i].payload;

                  if (res[i].payload.bot_id === botIdHolder.value) {
                    newClass.value = "text-gray-800 bg-light-success";
                    checkClass.value = "bi bi-check text-success";
                    text.value = "سرور ساخته شد ";
                    newClass2.value = "box d-none";

                    setTimeout(() => {
                      newClass3.value = "text-gray-800";
                      newClass4.value = "box";
                    }, 800);

                    if (
                      targetPlatform.value?.key.includes("whatsapp") &&
                      res[i].payload.platform === targetPlatform.value?.key
                    ) {
                      login("created");
                    } else {
                      if (
                        res[i].payload.platform === targetPlatform.value?.key
                      ) {
                        setTimeout(() => {
                          sendCode("build");
                        }, 5000);
                      }
                    }
                  }
                }
                if (res[i].type === "qr_data") {
                  delete_ids.value.push(res[i].id);
                  clearInterval(qr_start_count);
                  qrtimerCount.value = 0;

                  window.clearTimeout(timer);
                  if (server_status.value === "building") {
                    if (
                      targetPlatform.value?.key.includes("whatsapp") &&
                      res[i].payload.bot_id === botIdHolder.value
                    ) {
                      newClass3.value = "text-gray-800 bg-light-success";
                      checkClass2.value = "bi bi-check text-success";
                      text2.value = "QR کد ساخته شد ";
                      newClass4.value = "box d-none";

                      setTimeout(() => {
                        server_status.value = "enable";
                      }, 1000);
                    }
                  }
                  socketResponse.value = res[i].payload;

                  if (
                    res[i].payload.bot_id === botIdHolder.value &&
                    credentialWay.value === "qr"
                  ) {
                    newHolder[res[i].payload.platform].qrData =
                      res[i].payload.qr;
                  }
                }
                if (res[i].type === "setup_bot") {
                  delete_ids.value.push(res[i].id);
                  window.clearTimeout(timer);
                  socketResponse.value = res[i].payload;
                  if (
                    res[i].payload.bot_id === botIdHolder.value &&
                    credentialWay.value === "qr"
                  ) {
                    newHolder[res[i].payload.platform].step = "setup_bot";
                  }
                }
                if (res[i].type === "activation_code_sent") {
                  delete_ids.value.push(res[i].id);
                  window.clearTimeout(timer);
                  if (res[i].payload.bot_id === botIdHolder.value) {
                    if (!targetPlatform.value?.key.includes("whatsapp")) {
                      checkClass2.value = "bi bi-check text-success";
                      text2.value = "کد ارسال شد";
                      newClass4.value = "box d-none";

                      socketResponse.value = res[i].payload;
                      newHolder[res[i].payload.platform].step = "sendCode";
                      disableLoading(res[i].payload.platform);
                    }

                    setTimeout(() => {
                      server_status.value = "enable";
                    }, 1000);
                  }
                }
                if (res[i].type === "activation_code_error") {
                  delete_ids.value.push(res[i].id);
                  window.clearTimeout(timer);
                  socketResponse.value = res[i].payload;
                  if (res[i].payload.bot_id === botIdHolder.value) {
                    newHolder[res[i].payload.platform].step = "need_to_login";
                    // show Error
                    disableLoading(res[i].payload.platform);
                  }
                }
                if (res[i].type === "otp_send_code_error") {
                  delete_ids.value.push(res[i].id);
                  window.clearTimeout(timer);
                  socketResponse.value = res[i].payload;
                  if (res[i].payload.bot_id === botIdHolder.value) {
                    newHolder[res[i].payload.platform].step = "need_to_login";
                    // show Error
                    disableLoading(res[i].payload.platform);
                  }
                }
                if (res[i].type === "loading_messages") {
                  delete_ids.value.push(res[i].id);
                  window.clearTimeout(timer);
                  return;
                }
                if (res[i].type === "need_password") {
                  delete_ids.value.push(res[i].id);
                  window.clearTimeout(timer);
                  socketResponse.value = res[i].payload;
                  if (res[i].payload.bot_id === botIdHolder.value) {
                    newHolder[res[i].payload.platform].step = "password";
                    disableLoading(res[i].payload.platform);
                  }
                }
                if (res[i].type === "otp_registered") {
                  delete_ids.value.push(res[i].id);
                  window.clearTimeout(timer);
                  socketResponse.value = res[i].payload;
                  if (res[i].payload.bot_id === botIdHolder.value) {
                    fetchBotList();
                    newHolder[res[i].payload.platform].step = "ready";
                    disableLoading(res[i].payload.platform);
                  }
                }
                if (res[i].type === "bot_activated") {
                  delete_ids.value.push(res[i].id);
                  window.clearTimeout(timer);
                  socketResponse.value = res[i].payload;
                  if (res[i].payload.bot_id === botIdHolder.value) {
                    disableLoading(res[i].payload.platform);
                    fetchBotList();
                    newHolder[res[i].payload.platform].step = "ready";
                  }
                }
                if (res[i].type === "otp_login_error") {
                  delete_ids.value.push(res[i].id);
                  window.clearTimeout(timer);
                  socketResponse.value = res[i].payload;
                  if (res[i].payload.bot_id === botIdHolder.value) {
                    if (targetPlatform.value.key !== "bale") {
                      newHolder[res[i].payload.platform].step = "need_to_login";
                    }

                    disableLoading(res[i].payload.platform);
                  }
                }
                if (res[i].type === "otp_password_error") {
                  delete_ids.value.push(res[i].id);
                  window.clearTimeout(timer);
                  socketResponse.value = res[i].payload;
                  if (res[i].payload.bot_id === botIdHolder.value) {
                    newHolder[res[i].payload.platform].step = "need_to_login";
                    disableLoading(res[i].payload.platform);
                  }
                }
                if (res[i].type === "otp_need_register_fields") {
                  delete_ids.value.push(res[i].id);
                  window.clearTimeout(timer);
                  socketResponse.value = res[i].payload;
                  if (res[i].payload.bot_id === botIdHolder.value) {
                    newHolder[res[i].payload.platform].step = "signup";
                    disableLoading(res[i].payload.platform);
                  }
                }
                if (res[i].type === "login_with_phone_codes") {
                  delete_ids.value.push(res[i].id);
                  window.clearTimeout(timer);
                  // socketResponse.value = res[i].payload;
                  if (res[i].payload.bot_id === botIdHolder.value) {
                    newHolder[res[i].payload.platform].step = "signup";
                    disableLoading(res[i].payload.platform);

                    loginWithCodeData.value = res[i].payload;
                  }
                }
                if (res[i].type === "bot_deactivated") {
                  delete_ids.value.push(res[i].id);
                  clearInterval(start_count_account);
                  remove_loading.value = false;
                  window.clearTimeout(timer);
                  socketResponse.value = res[i].payload;
                  if (res[i].payload.bot_id === botIdHolder.value) {
                    newHolder[res[i].payload.platform].step = "need_to_login";
                    fetchBotList();
                    if (credentialWay.value === "qr") {
                      login("");
                      newHolder[res[i].payload.platform].qrData = "";
                    }
                    disableLoading(res[i].payload.platform);
                  }
                }
                if (res[i].type === "login_with_phone_activated") {
                  delete_ids.value.push(res[i].id);
                  // window.clearTimeout(timer);
                  newHolder.canLoginToWhatsAppWithCode = true;
                }
                if (res[i].type === "reload_bots") {
                  delete_ids.value.push(res[i].id);
                  window.clearTimeout(timer);
                  fetchBotList();
                }
                if (res[i].type === "refresh_tickets") {
                  delete_ids.value.push(res[i].id);
                  window.clearTimeout(timer);
                  store.dispatch(Actions.VERIFY_AUTH);
                }
                if (res[i].type === "refresh_browser") {
                  delete_ids.value.push(res[i].id);
                  window.clearTimeout(timer);
                  window.location.reload();
                }
                if (res[i].type === "bot_started") {
                  delete_ids.value.push(res[i].id);
                  restart_loading.value = false;
                }
                if (res[i].type === "bot_session_removed") {
                  delete_ids.value.push(res[i].id);
                  window.location.reload();
                }
                if (res[i].type === "login_expired") {
                  delete_ids.value.push(res[i].id);
                  if (res[i].payload.bot_id === botIdHolder.value) {
                    if (
                      targetPlatform.value?.key.includes("whatsapp") &&
                      res[i].payload.platform === targetPlatform.value?.key
                    ) {
                      Swal.fire({
                        text: "زمان اسکن QR کد به پایان رسیده، لطفا صفحه را مجددا بارگزاری کنید. ",
                        confirmButtonText: translate("بارگزاری مجدد"),
                        icon: "info",
                        reverseButtons: true,
                        customClass: {
                          confirmButton: "btn btn-info",
                        },
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.location.reload();
                        }
                      });
                    }
                  }
                }
              }
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    };

    watch(socketResponse, () => {
      if (socketResponse.value.message) {
        ElNotification({
          title: translate("pay attention"),
          message: socketResponse.value.message,
          type: "info",
        });
      }

      let message = "";
      if (socketResponse.value.errors) {
        socketResponse.value.errors.map((item) => {
          message += item;
        });
      }

      if (message !== "") {
        if (
          socketResponse.value.retryable &&
          socketResponse.value.retryable === true
        ) {
          Swal.fire({
            title: translate("pay attention"),
            text: message,
            icon: "error",
            showCancelButton: true,
            confirmButtonText: translate("Try again"),
            cancelButtonText: translate("cancel"),
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              sendCode("");
            }
          });
        } else {
          Swal.fire({
            text: message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: translate("ok"),
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
        }
      }
    });

    const credentialWay = computed(() => {
      return targetPlatform.value && targetPlatform.value.credential_methods
        ? targetPlatform.value.credential_methods[0]
        : "qr";
    });

    const server_name = ref();
    const login_disabled = ref(false);
    const login = (type) => {
      if (credentialWay.value === "otp")
        newHolder[targetPlatform.value.key].loading = true;
      login_disabled.value = true;
      let endPoint =
        credentialWay.value === "otp"
          ? `bot/auth-otp/${botIdHolder.value}/${targetPlatform.value.key}/login`
          : `bot/${
              route.params.bot_id !== undefined
                ? route.params.bot_id
                : props.botId
            }/login`;

      let params = {};
      if (credentialWay.value === "otp") {
        params = {
          code: newHolder[targetPlatform.value.key].code,
          mobile: newHolder[targetPlatform.value.key].mobile,
        };
      }
      if(targetPlatform.value.key === 'whatsapp_vip'){
         params = {
          platform:'whatsapp_vip',
        };
      }

      if (
        route.name === "connect-to-platform" ||
        route.name === "welcome-wizard"
      ) {
        ApiService.post(endPoint, params)
          .then((data) => {
            if (type !== "created") {
              server_status.value = data.data.data.server_status;
              if (server_status.value === "building") {
                newClass2.value = "box";
              }
            }
            server_name.value = data.data.data.server.name;

            login_disabled.value = false;
          })
          .catch(({ response }) => {
            login_disabled.value = false;

            if (response && response.data)
              store.commit(Mutations.SET_ERROR, response.data);
            // newHolder[targetPlatform.value.key].loading = false;
          });
      }
    };

    const sendCode = (type) => {
      newHolder[targetPlatform.value.key].loading = true;
      ApiService.post(
        `bot/auth-otp/${botIdHolder.value}/${targetPlatform.value.key}/send-code`,
        {
          mobile: newHolder[targetPlatform.value.key].mobile,
        }
      )
        .then((data) => {
          server_name.value = data.data.data.server.name;
          if (type !== "build") {
            server_status.value = data.data.data.server_status;
          }
        })
        .catch(({ response }) => {
          newHolder[targetPlatform.value.key].loading = false;
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        });
    };

    const reConnect = () => {
      newHolder[targetPlatform.value.key].loading = true;
      store.commit(Mutations.SET_ERROR, []);

      ApiService.post(
        `bot/${botIdHolder.value}/${targetPlatform.value.key}/start`,
        {}
      )
        .then(() => {
          //
        })
        .catch(({ response }) => {
          newHolder[targetPlatform.value.key].loading = false;
          if (response) store.commit(Mutations.SET_ERROR, response.data);
        });
    };

    const signUp = () => {
      newHolder[targetPlatform.value.key].loading = true;
      ApiService.post(
        `/bot/auth-otp/${botIdHolder.value}/${targetPlatform.value.key}/complete-register-fields`,
        {
          fields: {
            name: newHolder[targetPlatform.value.key].first_name || "",
            first_name: newHolder[targetPlatform.value.key].first_name || "",
            last_name: newHolder[targetPlatform.value.key].last_name || "",
          },
        }
      )
        .then(() => {
          //
        })
        .catch(({ response }) => {
          if (response) store.commit(Mutations.SET_ERROR, response.data);
          newHolder[targetPlatform.value.key].loading = false;
        });
    };

    const sendTWoStep = () => {
      newHolder[targetPlatform.value.key].loading = true;
      store.commit(Mutations.SET_ERROR, []);
      ApiService.post(
        `bot/auth-otp/${botIdHolder.value}/${targetPlatform.value.key}/password`,
        {
          password: newHolder[targetPlatform.value.key].password,
        }
      )
        .then(() => {
          //
        })
        .catch(({ response }) => {
          newHolder[targetPlatform.value.key].loading = false;
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        });
    };

    const disableLoading = (platform) => {
      newHolder[platform].loading = false;
      if (platform) {
        timerHandler[platform] = false;
      }
    };

    const handlePhone = (phoneObject, target) => {
      if (phoneObject.valid !== true) return;
      newHolder[target].phone_code = phoneObject.countryCallingCode;
      newHolder[target].mobile = phoneObject.number;
      newHolder[target].phone_data = phoneObject;
    };

    const getCodes = (newCode, target) => {
      newHolder[target].code = newCode;
      if (targetPlatform.value?.otp_code_type?.type !== "string") {
        login("");
      }
    };

    // server actions
    const delete_disabled = ref(false);
    const remove_loading = ref(false);
    const restart_loading = ref(false);
    const delete_text = ref("حذف نشست فعال");
    const timerCount = ref(30);
    const loading_text = ref("");
    var start_count;

    const delete_server = (type) => {
      // delete_disabled.value = true;
      if (type === "loading") {
        remove_loading.value = true;
        loading_text.value = "در حال حذف نشست ...";
      }
      // delete_text.value = "در حال حذف...";
      clearInterval(start_count);
      timerCount.value = 40;
      start_count = setInterval(() => {
        timerCount.value -= 1;
        if (timerCount.value === 0) {
          clearInterval(start_count);
          if (type === "loading") {
            window.location.reload();
          } else {
            console.log(credentialWay.value);

            if (credentialWay.value === "qr") {
              login("");
            } else {
              sendCode("");
            }
          }
        }
      }, 1000);

      ApiService.post(
        `bot/${botIdHolder.value}/${targetPlatform.value.key}/delete-bot`,
        {}
      )
        .then((data) => {
          ElNotification({
            title: translate("pay attention"),
            message: "در حال حذف نشست شما... لطفا منتظر بمانید.",
            type: "info",
          });

          if (JwtService.getUserId()) {
            window.Echo.private("user." + JwtService.getUserId()).listen(
              ".bot_session_removed",
              (res) => {
                if (res.bot_id === botIdHolder.value) {
                  if (type === "loading") {
                    window.location.reload();
                  } else {
                    console.log(credentialWay.value);

                    if (credentialWay.value === "qr") {
                      login("");
                    } else {
                      sendCode("");
                    }
                  }
                }
              }
            );
          }

          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
          newHolder[targetPlatform.value.key].loading = false;
        });
    };

    const restart_server = () => {
      ApiService.post(
        `bot/${botIdHolder.value}/${targetPlatform.value.key}/restart`,
        {}
      )
        .then((data) => {
          // Swal.fire({
          //   text: "پلتفرم شما مجددا راه اندازی شد.",
          //   icon: "success",
          //   buttonsStyling: false,
          //   confirmButtonText: "بسیار خب",
          //   customClass: {
          //     confirmButton: "btn btn-primary",
          //   },
          // });
          ElNotification({
            title: translate("pay attention"),
            message: "پلتفرم شما مجددا راه اندازی شد.",
            type: "info",
          });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
          newHolder[targetPlatform.value.key].loading = false;
        });
    };

    const allBotsHolder = AllBots();
    const { powerOff } = allBotsHolder;
    const powerOffBot = async (id, pl) => {
      remove_loading.value = true;
      loading_text.value = "درحال تغییر اکانتشما ... ";
      start_count_account = setInterval(() => {
        timerCount.value -= 1;
        if (timerCount.value === 0) {
          // clearInterval(start_count_account);
          timerCount.value = 30;
        }
      }, 1000);
      await powerOff(id, pl);

      ElNotification({
        title: translate("pay attention"),
        message: "لطفا تا زمان قطع ارتباط پلتفرم، هیچ فعالیتی انجام ندهید.",
        type: "info",
      });
    };

    /// otp End
    const activePlatformHolder = ref();
    const handleConnect = async (platform) => {
      server_status.value = "enabled";
      server_name.value = "";
      await setDefaultBotId(botIdHolder.value);

      let intervalCounter = ref(0);
      const intervalId = setInterval(async () => {
        intervalCounter.value++;
        if (intervalCounter.value > 30) {
          clearInterval(intervalId);
          intervalCounter.value = 0;
        }
        if (!findBot.value) {
          await fetchBotList();
        } else {
          clearInterval(intervalId);

          await setTargetPlatform(platform);

          text.value = "در حال ساخت سرور...";
          text2.value =
            targetPlatform.value?.key === "whatsapp"
              ? "در حال ایجاد QR کد..."
              : "در حال ارسال کد";

          checkClass.value = "bi bi-check text-success d-none";
          checkClass2.value = "bi bi-check text-success d-none";
          newClass.value = "";
          newClass2.value = "box d-none";
          newClass3.value = "";
          newClass4.value = "box d-none";

          activePlatformHolder.value = await findTargetPlatformInBot(
            defaultPlatform.value
          );
          if (newHolder[platform].step === "") {
            newHolder[platform].step =
              (findTargetPlatformInBot(targetPlatform.value.key) &&
                findTargetPlatformInBot(targetPlatform.value.key)["status"]) ??
              "need_to_login";
          }

          if (findBot.value && findBot.value.package)
            if (
              credentialWay.value === "qr" &&
              newHolder[platform].step !== "ready" &&
              newHolder[platform].qrData === ""
            ) {
              // if (
              //   !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              //     navigator.userAgent
              //   )
              // ) {
              //   login("");
              // }
              let whatsapp_default_method = localStorage.getItem(
                "whatsapp_default_method"
              );

              if (whatsapp_default_method !== "phone") {
                login("");
              }
            }
        }
      }, 1000);
    };


   const prevPer = ref();
   const userInfo = ref();


    onMounted(async () => {
      // connectToSocket();

      setTimeout(() => {
        let per: any = localStorage.getItem("user_info");
        userInfo.value = JSON.parse(per).type;
        // let user: any = localStorage.getItem("user_info");
        // userType.value = JSON.parse(user).type;

        let prev_user: any = localStorage.getItem("previous_user_info") || {};
        prevPer.value = JSON.parse(prev_user).type;
      }, 3000);



      await handleConnect(defaultPlatform.value);

      setCurrentPageBreadcrumbs(translate("platform management"), [
        translate("My subscriptions"),
      ]);
    });

    const loginWithQr = () => {
      newHolder.whatsapp.qrData = "";
      newHolder.otpWay = "qr";
      login("");
    };

    const addDataLayerPurchase = async () => {
      await setDefaultBotId(botIdHolder.value);
      if (!findBot.value) await fetchBotList();

      let data = {
        event: "purchase",
        ecommerce: {
          value: Number(findBot.value?.package?.paid_price),
          tax: Number(findBot.value?.package?.tax_price) ?? 0,
          currency: "USD",
          coupon: findBot.value?.package?.discount ?? "",
          coupon_discount: Number(findBot.value?.package?.discount_price),
          transaction_id: route.params.transaction_id ?? "after_online_payment",
          items: [] as any,
        },
      };
      findBot.value?.package.platforms.forEach((item, i) => {
        data.ecommerce.items.push({
          item_name: item,
          item_variant: findBot.value?.package?.package?.name,
          item_brand: "پیام رسان اتومیشن انبوه",
          index: i,
          price: Number(findBot.value?.package?.package.platforms_price[item]),
          quantity: 1,
        });
      });

      const dataLayerScript = document.createElement("script");
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(${JSON.stringify(data)});
      `;

      document.head.appendChild(dataLayerScript);
    };

    const isMobile = () => {
      var whatsapp_default_method = localStorage.getItem(
        "whatsapp_default_method"
      );

      if (whatsapp_default_method === "phone") {
        newHolder.otpWay = "phone";
        newHolder.canLoginToWhatsAppWithCode = true;
      }
      // if (
      //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      //     navigator.userAgent
      //   )
      // ) {
      //   newHolder.otpWay = "code";
      //   newHolder.canLoginToWhatsAppWithCode = true;
      // }
    };

    onMounted(() => {
      isMobile();
      if (
        route.params.transaction_id &&
        !window.localStorage.getItem(`${route.params.transaction_id}`)
      ) {
        // save to localStorage
        window.localStorage.setItem(
          `${route.params.transaction_id}`,
          `${route.params.transaction_id}`
        );

        // add data layer
        addDataLayerPurchase();
      }

      pulling_data.value = localStorage.getItem("user_info");
      // if (JSON.parse(pulling_data.value).polling_mode === true) {
      //   setInterval(() => {
      //     pulling();
      //   }, 5000);
      // } else {
      //   connectToSocket();
      // }

      if (JSON.parse(pulling_data.value).send_polling_requests === true) {
        // timer = window.setTimeout(function () {
        //   // login("");
        //   delete_server("");
        // }, 80000);
        setInterval(() => {
          pulling();
        }, 5000);
      }

      // if (JSON.parse(pulling_data.value).polling_mode === false) {
      //   // timer = window.setTimeout(function () {
      //   //   // login("");
      //   //   delete_server("");
      //   // }, 80000);
      //   // connectToSocket();
      // }
    });

    return {
      open_change_server,
      change_server,
      changeServerDialog,
      machines,
      select_machine,
      botIdHolder,
      route,
      translate,
      server_name,
      platformsList,
      targetPlatform,
      powerOffBot,
      setTargetPlatform,
      findBot,
      findTargetPlatformInBot,
      checkPackagePlatform,
      credentialWay,
      loading_text,
      getCodes,
      login,
      sendCode,
      handlePhone,
      signUp,
      sendTWoStep,
      reConnect,
      botList,
      handleConnect,
      timerHandler,
      newHolder,
      loginWithCodeData,
      loginWithQr,
      defaultPlatform,
      prevPer,
      userInfo,

      server_status,
      text,
      text2,
      checkClass,
      checkClass2,
      newClass,
      newClass2,
      newClass3,
      newClass4,
      CreatingtimerCount,
      qrtimerCount,

      delete_server,
      restart_server,
      timerCount,
      remove_loading,
      restart_loading,
      delete_disabled,
      delete_text,
      login_disabled,
    };
  },
});
