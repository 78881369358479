
import { defineComponent, onMounted, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import PhoneInput from "@/components/Phone/PhoneInput.vue";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import JwtService from "@/core/services/JwtService";
export default defineComponent({
  name: "connect-to-whatsapp-with-code",
  components: {
    PhoneInput,
  },
  props: {
    botId: {
      type: String,
      default: "",
    },
    eventData: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const store = useStore();
    const loading = ref(true);
    const route = useRoute();

    const holder = ref({
      phone_code: "",
      mobile: "",
      phone_data: "",
      codes: "",
      step: "phone", // phone | code
    });

    const handlePhone = (phoneObject, target) => {
      if (phoneObject.valid !== true) return;
      holder.value.phone_code = phoneObject.countryCallingCode;
      holder.value.mobile = phoneObject.number;
      holder.value.phone_data = phoneObject;
    };

    const submitButton = ref<HTMLButtonElement | null>(null);
    const submitButton1 = ref<HTMLButtonElement | null>(null);

    const sendPhoneData = () => {
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      ApiService.post(
        `bot/auth-with-phone/${botIdHolder.value}/whatsapp/send-request`,
        {
          phone: holder.value.mobile,
        }
      )
        .then(() => {
          // holder.value.step = "code";
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);

          if (submitButton.value) {
            //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
          }
        })
        .finally(() => {
          setTimeout(() => {
            if (submitButton.value) {
              //Deactivate indicator
              submitButton.value?.removeAttribute("data-kt-indicator");
              // eslint-disable-next-line
              submitButton.value!.disabled = false;
            }
          }, 30000);
        });
    };

    // const connectToSocket = () => {
    //   if (JwtService.getUserId()) {
    //     window.Echo.private("user." + JwtService.getUserId()).listen(
    //       ".login_with_phone_codes",
    //       (e) => {
    //         holder.value.codes = e?.codes;
    //         if (submitButton.value) {
    //           //Deactivate indicator
    //           submitButton.value?.removeAttribute("data-kt-indicator");
    //           // eslint-disable-next-line
    //           submitButton.value!.disabled = false;
    //         }
    //         holder.value.step = "code";
    //       }
    //     );
    //   }
    // };

    const pulling_data = ref();
    const pulling = () => {
      if (props?.eventData?.codes) {
        console.log(222222222);
        holder.value.codes = props.eventData?.codes;
        if (submitButton.value) {
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        }
        holder.value.step = "code";
      }
    };

    const back = () => {
      ApiService.post(`bot/${botIdHolder.value}/login`, {})
        .then(() => {
          emit("loginWithQr");
        })
        .catch(({ response }) => {
          // if (response && response.data)
          //   store.commit(Mutations.SET_ERROR, response.data);
          // if (submitButton.value) {
          //   //Deactivate indicator
          //   submitButton.value?.removeAttribute("data-kt-indicator");
          //   // eslint-disable-next-line
          //   submitButton.value!.disabled = false;
          // }
        })
        .finally(() => {
          // setTimeout(() => {
          //   if (submitButton.value) {
          //     //Deactivate indicator
          //     submitButton.value?.removeAttribute("data-kt-indicator");
          //     // eslint-disable-next-line
          //     submitButton.value!.disabled = false;
          //   }
          // }, 30000);
        });
    };

    const copyCode = () => {
      let code = "";
      for (let i = 0; i < holder.value.codes.length; i++) {
        code += holder.value.codes[i];
      }
      navigator.clipboard
        .writeText(code)
        .then(() => {
          // می‌توانید در اینجا اقدامات دیگری انجام دهید
        })
        .catch((error) => {
          // console.error("Unable to copy text to clipboard:", error);
        });
    };

    const botIdHolder = computed(() => {
      if (props.botId && props.botId !== "") return props.botId;
      return route.params.bot_id;
    });

    onMounted(() => {
      // connectToSocket();

      pulling_data.value = localStorage.getItem("user_info");
      // if (JSON.parse(pulling_data.value).polling_mode === true) {
      //   setInterval(() => {
      //     pulling();
      //   }, 8000);
      // } else {
      //   connectToSocket();
      // }
      // connectToSocket();

      if (JSON.parse(pulling_data.value).send_polling_requests === true) {
        setInterval(() => {
          pulling();
        }, 5000);
      }

      if (JSON.parse(pulling_data.value).polling_mode === false) {
        // connectToSocket();
      }
    });

    return {
      translate,
      loading,
      copyCode,

      holder,
      handlePhone,
      sendPhoneData,
      back,
      botIdHolder,
      submitButton,
      submitButton1,
    };
  },
});
