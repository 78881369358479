
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { StepperComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { useForm } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import UserInfo from "@/core/services/UserInfo";
import { useI18n } from "vue-i18n";
import { ElNotification } from "element-plus/es";
import { Countries } from "@/store/pinia/Countries";
import { storeToRefs } from "pinia";
import { platforms } from "@/store/pinia/Platforms";
import UploadFile from "@/components/uploadFiles/UploadFile.vue";
import Emoji from "@/components/Emoji.vue";
import NewConnect from "@/views/pages/SubscriptionConnections/component/NewConnect.vue";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import Echo from "laravel-echo";
import { AllBots } from "@/store/pinia/AllBots";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import ShowFile from "@/components/ShowFile.vue";
import { useRouter } from "vue-router";
import FreeTestSubscription from "@/components/FreeTestSubscription.vue";
import { allPackages } from "@/store/pinia/AllPackages";

declare global {
  interface Window {
    Echo: any;
    Pusher: any;
  }
}

export default defineComponent({
  name: "kt-vertical-wizard",
  components: {
    UploadFile,
    Emoji,
    NewConnect,
    ShowFile,
    FreeTestSubscription,
  },
  data: function () {
    return {
      minutes: 30,
    };
  },
  created() {
    const { t, te } = useI18n();
    const translateText = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    let checkPusherTimeOut = false;
    if (!JwtService.getToken()) {
      this.$router.push({ name: "sign-in" });
      return;
    } else {
      const MAX_CONNECTION_RETRIES = 20;
      let connectionRetries = 0;
      // const sendConnectionFailureToBackend = () => {
      //   ApiService.post("socket/set-error", {})
      //     .then(() => {
      //       //
      //     })
      //     .catch(() => {
      //       return;
      //     });
      // };

      // const checkAndDisconnectPusher = () => {
      //   checkPusherTimeOut = true;
      //   window.Echo.disconnect();
      //   Swal.fire({
      //     text: translateText("Are you online ?"),
      //     icon: "info",
      //     buttonsStyling: false,
      //     confirmButtonText: translateText("yes"),
      //     customClass: {
      //       confirmButton: "btn btn-primary",
      //     },
      //   }).then(() => {
      //     checkPusherTimeOut = false;
      //     connectToSocket();
      //   });
      // };

      // const connectToSocket = () => {
      //   let pusherKey = window.localStorage.getItem("app_key");
      //   let socketInfo = JSON.parse(String(window.localStorage.getItem("socket")));

      //   window.Pusher = require("pusher-js");
      //   let socketOptions = {
      //     broadcaster: "pusher",
      //     key: pusherKey,
      //     encrypted: true,
      //     forceTLS: true,
      //     wsHost: socketInfo.host ? socketInfo.host : 'wsocket.inboxino.com',
      //     wpPort: 443,
      //     enabledTransports: ["ws", "wss"],
      //     authorizer: (channel, options) => {
      //       return {
      //         authorize: (socketId, callback) => {
      //           fetch(process.env.VUE_APP_ECHO_AUTH_ENDPOINT, {
      //             method: "POST",
      //             headers: {
      //               "Content-Type": "application/json",
      //               Authorization: "Bearer " + JwtService.getToken(),
      //             },
      //             body: JSON.stringify({
      //               socket_id: socketId,
      //               channel_name: channel.name,
      //             }),
      //           })
      //             .then((response) => response.json())
      //             .then((data) => {
      //               callback(false, data);
      //             })
      //             .catch(() => {
      //               if (checkPusherTimeOut === false) {
      //                 connectToSocket();
      //               }
      //             });
      //         },
      //       };
      //     },
      //   };
      //   window.Echo = new Echo(socketOptions);
      //   window.Echo.connector.pusher.connection.bind("disconnected", () => {
      //     connectionRetries++;
      //     if (connectionRetries >= MAX_CONNECTION_RETRIES) {
      //       // sendConnectionFailureToBackend();
      //       return;
      //     }
      //     if (checkPusherTimeOut === false) {
      //       // connectToSocket();
      //     }
      //   });
      //   window.Echo.private("user." + JwtService.getUserId());
      // };

      // setInterval(() => {
      //   if (this.minutes > 0) this.minutes -= 1;
      //   else checkAndDisconnectPusher();
      // }, 60 * 1000);

      // if (checkPusherTimeOut === false) {
      //   connectToSocket();
      // }
    }
  },
  setup() {
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref(true);
    const router = useRouter();
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const user = UserInfo.getUser();

    const freeBotId = ref(""); // wa-3973943

    const allPackagesHolder = allPackages();
    const { findFreePackageId } = storeToRefs(allPackagesHolder);
    const { fetchPackageList } = allPackagesHolder;

    const platformsHolder = platforms();
    const { fetchPlatforms } = platformsHolder;
    const { platformsList } = storeToRefs(platformsHolder);

    const AllBotsHolder = AllBots();
    const { findBot, findFreeBots } = storeToRefs(AllBotsHolder);
    const { fetchBotList, setDefaultBotId } = AllBotsHolder;

    watch(freeBotId, () => {
      setDefaultBotId(freeBotId.value);
    });

    const bulk = ref({
      messages: [
        {
          message: "",
          message_type: "message",
          attachment_file: "",
          attachment_file_url: "",
          with_file: false,
        },
      ] as any,
      recipients: [] as any,
      bot_id: computed(() => {
        return freeBotId.value;
      }), //botId.value,
      platformsHolder: "" as any, // this in single
      platforms: [] as any, // this in single [bulk.value.platformsHolder]
      send_schedule: "",
      setting: {
        start_clock: "",
        finish_clock: "",
        send_count: 3,
        break_time: 2,
        messages_count_per_day: 100,
      },
      with_country_code: true,
      country_code: user?.country?.phone_code,
      excel_phone_number_count: 0,
    });

    const addNewItems = () => {
      bulk.value.messages.push({
        message: "",
        message_type: "message",
        attachment_file: "",
        with_file: false,
      });
    };

    const removeItem = (index) => {
      bulk.value.messages.splice(index, 1);
    };

    const fileUploadedCallBack = (file, activeIndex) => {
      bulk.value.messages[activeIndex].message_type = file.file_type;
      bulk.value.messages[activeIndex].attachment_file_url = file.url;

      if (bulk.value.messages[activeIndex].message_type === "file") {
        bulk.value.messages[activeIndex].with_file = true;
      } else {
        bulk.value.messages[activeIndex].with_file = false;
      }

      bulk.value.messages[activeIndex].attachment_file = file.path;
      if (file.only_as_file) {
        bulk.value.messages[activeIndex].with_file = true;
        ElNotification({
          title: translate("pay attention"),
          message: translate(
            "Your sent file exceeds the allowed size of images and videos in subscription and must be sent as a file"
          ),
          type: "warning",
        });
      }
    };

    const countriesListHolder = Countries();
    const { countriesList } = storeToRefs(countriesListHolder);

    const inputNumberType = ref({
      manual: {
        name: "manual",
        icon: "art/art005.svg",
        title: "",
      },
      // phonebook: {
      //   name: "phonebook",
      //   icon: "communication/com005.svg",
      //   title: "",
      // },
      excel: {
        name: "excel",
        icon: "files/fil007.svg",
        title: "",
      },
      variable: {
        name: "variable",
        icon: "general/gen005.svg",
        title: "",
      },
    });
    const numberType = ref("manual");
    const recipients = ref("");
    const importExcelFileNumbers = ref();
    const uniq = (array) => {
      return Array.from(new Set(array));
    };
    const getRecipients = () => {
      return uniq(recipients.value.split("\n"));
    };

    const handlePhoneNumbersCount = computed(() => {
      if (numberType.value === "excel" || numberType.value === "variable") {
        return bulk.value.excel_phone_number_count;
      } else {
        return recipients.value.split("\n").filter((item) => item !== "")
          .length;
      }
    });

    const handlePhoneNumbers = () => {
      if (numberType.value === "manual")
        bulk.value.recipients = getRecipients();
      if (numberType.value === "excel" || numberType.value === "variable")
        bulk.value["import_file"] = importExcelFileNumbers.value;
    };

    const disableItemsInUploading = ref({
      message: translate("uploading"),
      status: false,
      index: null,
    });

    const checkFileInUploading = (status, index) => {
      disableItemsInUploading.value.status = status;
      disableItemsInUploading.value.index = status === true ? index : null;
    };

    const exelUploadedCallBack = (file) => {
      importExcelFileNumbers.value = file.path;
      bulk.value.excel_phone_number_count = file.excel_phone_number_count;
    };

    const emojiHelper = ref({
      activeIndex: 0 as number,
    });

    const addEmoji = (emoji) => {
      bulk.value.messages[emojiHelper.value.activeIndex].message += emoji;
    };

    const _stepperObj = ref<StepperComponent | null>(null);
    const verticalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const formData = ref();

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        verticalWizardRef.value as HTMLElement
      );

      setCurrentPageBreadcrumbs("Vertical", ["Pages", "Wizards"]);
    });

    const createAccountSchema = [
      Yup.object({
        // nameOnCard: Yup.string().required().label("Name On Card"),
        // cardNumber: Yup.string().required().label("Card Number"),
        // cardExpiryMonth: Yup.string().required().label("Expiration Month"),
        // cardExpiryYear: Yup.string().required().label("Expiration Year"),
        // cardCvv: Yup.string().required().label("CVV"),
      }),
    ];

    const currentSchema = computed(() => {
      return createAccountSchema[currentStepIndex.value];
    });

    const { resetForm, handleSubmit } = useForm();

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      // connect to platform
      if (currentStepIndex.value === 1) {
        let countActiveBotPlatforms = findBot.value?.platforms?.filter(
          (item) => item.status === "ready"
        ).length;

        if (countActiveBotPlatforms < 1) {
          ElNotification({
            title: translate("At least one platform connection is required"),
            message: translate("Connect one of the platforms"),
            type: "error",
          });
          return;
        }
      }

      // set bulk message
      if (currentStepIndex.value === 2) {
        if (bulk.value.platforms === "") {
          ElNotification({
            title: translate("Platform selection is required."),
            message: translate("Choose a platform."),
            type: "error",
          });
          return;
        }

        if (
          handlePhoneNumbersCount.value < 1 ||
          handlePhoneNumbersCount.value > 100
        ) {
          ElNotification({
            title:
              handlePhoneNumbersCount.value < 1
                ? translate("Enter the numbers.")
                : translate("The number of numbers exceeds the limit."),
            message:
              handlePhoneNumbersCount.value < 1
                ? translate(
                    "Enter the number of people you want to send a message to."
                  )
                : translate(
                    "You cannot enter more than 100 numbers for testing."
                  ),
            type: "error",
          });
          return;
        }

        let findEmptyText = bulk.value.messages.filter(
          (item) => item.message === "" && item.attachment_file === ""
        );
        if (findEmptyText.length > 0) {
          {
            ElNotification({
              title: translate("Message is required."),
              message: translate("Enter your message."),
              type: "error",
            });
            return;
          }
        }
      }

      currentStepIndex.value++;
      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const store = useStore();

    const formSubmit = () => {
      if (disableItemsInUploading.value.status === true) {
        ElNotification({
          title: translate("error"),
          message: translate(
            "A file is uploading Please wait until the file is uploaded"
          ),
          type: "error",
        });
      }

      bulk.value.messages = bulk.value.messages.map((item, index) => {
        return {
          message: item.message.replace(/(?:\r\n|\r|\n)/g, "<br>"),
          message_type: item.with_file ? "file" : item.message_type,
          attachment_file: item.attachment_file,
          with_file: item.with_file,
        };
      });

      bulk.value.platforms = [bulk.value.platformsHolder];

      handlePhoneNumbers();

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      ApiService.post("message/send", bulk.value)
        .then(() => {
          Swal.fire({
            text: translate(
              "Your message is placed in the sending queue and will be sent in a few minutes، Your phone does not need to be connected to the Internet anymore"
            ),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: translate("View panel"),
            customClass: {
              confirmButton: "btn fw-bold btn-success",
            },
          }).then(() => {
            router.push("/services/bulk-message");
          });
        })
        .catch((e) => {
          if (e.response && e.response && e.response.error)
            store.commit(Mutations.SET_ERROR, e.response.data);
          ElNotification({
            title: translate("error"),
            message: translate("Error sending mass message"),
            type: "error",
          });
        })
        .finally(() => {
          if (submitButton.value) {
            //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
          }
        });
    };

    const asideStepsTitle = ref([
      {
        title: translate("welcome"),
        desc: translate("Free Subscription"),
      },
      {
        title: translate("Platform Connection"),
        desc: translate("Connect Platforms"),
      },
      {
        title: translate("Setting Bulk Message"),
        desc: translate("Enter your bulk message"),
      },
      {
        title: translate("end"),
        desc: translate("enjoy"),
      },
    ]);

    const takeFreeBotId = (bot_id) => {
      freeBotId.value = bot_id;
      loading.value = false;
    };

    onMounted(async () => {
      fetchPlatforms();
      await fetchBotList();
      // set free bot id latest created bots
      if (findFreeBots.value.length > 0) {
        freeBotId.value = findFreeBots.value[0]["bot_id"];
        loading.value = false;
      }

      // if free package not exist push to bot list
      await fetchPackageList();
      if (!findFreePackageId.value && findFreeBots.value.length < 1) {
        Swal.fire({
          text: translate("متاسفانه پکیج تست رایگان برای شما فعال نمی‌باشد."),
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: translate("ok"),
          customClass: {
            confirmButton: "btn btn-warning",
          },
        }).then(() => {
          router.push("/bot-connections");
        });
      }
    });

    return {
      verticalWizardRef,
      previousStep,
      handleStep,
      formSubmit,
      totalSteps,
      currentStepIndex,

      asideStepsTitle,
      user,
      bulk,
      countriesList,
      platformsList,
      numberType,
      inputNumberType,
      recipients,
      disableItemsInUploading,
      checkFileInUploading,
      exelUploadedCallBack,
      fileUploadedCallBack,
      addNewItems,
      removeItem,
      translate,
      emojiHelper,
      addEmoji,
      freeBotId,
      findBot,
      handlePhoneNumbersCount,
      takeFreeBotId,
      loading,
      submitButton,
      t,
    };
  },
});
