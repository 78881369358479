
import { computed, defineComponent, onMounted, ref, watchEffect } from "vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { phoneExample } from "@/components/Phone/examplePhoneNumbers";
import { useI18n } from "vue-i18n";

import { Countries } from "@/store/pinia/Countries";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "input-phone-national-number",
  components: {
    VueTelInput,
  },
  props: {
    phoneType: {
      type: String,
      default: null,
    },
    dataName: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    clasess: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    platform: {
      type: String,
      default: "",
    },
  },
  emit: ["phoneCode", "phoneObject"],
  setup(props: any, { emit }) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const userInput = ref();

    const loading = ref(false);
    const formData = ref({
      phone_code: "",
      mobile: "",
      level: "",
      result: {},
    });

    const countriesListHolder = Countries();
    const { countriesList, defaultCountry } = storeToRefs(countriesListHolder);
    const { getCountries } = countriesListHolder;

    const defaultItem = ref();
    const defaultItemPhoneCode = ref();
    const countries = ref(countriesList.value);

    const formDataForValidate = computed(() => {
      return defaultCountry.value?.phone_code + "" + formData.value.mobile;
    });

    watchEffect(() => {
      if (defaultCountry.value) {
        countries.value = countriesList.value;
        defaultItem.value = defaultCountry.value.name;
        defaultItemPhoneCode.value = defaultCountry.value.phone_code;
        loading.value = false;
      } else {
        loading.value = true;
      }
    });

    const validate = (phoneObject) => {
      formData.value.result = phoneObject;
      emit("phoneObject", phoneObject, props?.target);
    };

    onMounted(() => {
      if (!countriesList.value) getCountries();
    });

    const filterCountry = computed((value) => {
      if (!value) return countries.value;
      return countries.value.map((item) => {
        return item.name.includes(value);
      });
    });

    const filterCountryWithCode = computed((value) => {
      if (!value) return countries.value;
      return countries.value.map((item) => {
        return item.phone_code.includes(value);
      });
    });

    const checkData = (value) => {
      if (value === "defaultItem") {
        defaultCountry.value = countries.value.find(
          (item) => item.name === defaultItem.value
        );
        defaultItemPhoneCode.value = defaultCountry.value.phone_code;
      }
      if (value === "phone_code") {
        defaultCountry.value = countries.value.find(
          (item) => item.phone_code === defaultItemPhoneCode.value
        );
        defaultItem.value = defaultCountry.value.name;
      }
    };

    const handleEmit = () => {
      emit(
        "phoneCode",
        props.dataName === null
          ? defaultItemPhoneCode.value
          : countries.value.find(
              (country) => country.phone_code === defaultItemPhoneCode.value
            )["alpha2_code"]
      );
    };

    onMounted(() => {
      if (props.data && props?.data.phone_data) {
        formData.value.mobile = props?.data?.phone_data?.nationalNumber;
      }
    });

    return {
      countries,
      defaultCountry,
      formData,
      validate,
      translate,
      loading,
      filterCountry,
      filterCountryWithCode,
      defaultItem,
      defaultItemPhoneCode,
      checkData,
      formDataForValidate,
      phoneExample,
      handleEmit,
      userInput,
    };
  },
});
