
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import emoji from "@/core/data/emoji";

export default defineComponent({
  name: "emoji-handler-component",
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    return {
      translate,
      emoji,
    };
  },
});
