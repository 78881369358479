<template>
  <span>{{ second }} </span>
</template>

<script>
import { defineComponent, onMounted, onUpdated, ref } from "vue";

export default defineComponent({
  name: "timer-component",
  props: {
    platform: {
      type: String,
      default: "",
    },
    status: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const second = ref(180);
    const isActive = ref(props.status);
    let intervalId = null;

    function startTimer() {
      intervalId = setInterval(() => {
        second.value--;
        if (second.value === 0) {
          second.value = 180;
        }
      }, 1000);
    }

    function stopTimer() {
      clearInterval(intervalId);
      intervalId = null;
    }

    function toggle() {
      isActive.value = !isActive.value;
      if (isActive.value) {
        startTimer();
      } else {
        stopTimer();
      }
    }

    onMounted(() => {
      startTimer();
    });

    onUpdated(() => {
      if (props.status !== isActive.value) {
        isActive.value = props.status;
        if (isActive.value) {
          startTimer();
        } else {
          stopTimer();
        }
      }
    });

    return {
      second,
      isActive,
      toggle,
    };
  },
});
</script>
