
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "kt-show-file-viewer",
  props: {
    fileName: String || undefined,
    classes: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const fileUrl = computed(() => {
      return props.fileName;
    });

    const isImage = computed(() =>
      /\.(jpg|jpeg|png|gif)$/.test(fileUrl.value as string)
    );
    const isVideo = computed(() =>
      /\.(mp4|webm|ogg|mkv)$/.test(fileUrl.value as string)
    );
    const isAudio = computed(() =>
      /\.(mp3|wav|ogg)$/.test(fileUrl.value as string)
    );

    return {
      fileUrl,
      isImage,
      isVideo,
      isAudio,
      translate,
    };
  },
});
