
import { defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { statusIcon, statusColor } from "@/core/services/HelpersFun";
import Alert from "@/components/Alert.vue";
import JwtService from "@/core/services/JwtService";
import { Form } from "vee-validate";
import { AllBots } from "@/store/pinia/AllBots";
import { platforms } from "@/store/pinia/Platforms";
import { storeToRefs } from "pinia";
import Swal from "sweetalert2/dist/sweetalert2.js";
import IncreasePoints from "@/components/IncreasePoints.vue";
import Status from "@/components/Status.vue";
import { Servers } from "@/store/pinia/Servers";
import { checkAutoLogin } from "@/core/services/AutoLogin";
import DetailsModal from "@/views/pages/Admin/bots/component/detailsModal.vue";

export default defineComponent({
  name: "kt-status-platforms",
  components: { Status, Alert, IncreasePoints, DetailsModal },
  emit: ["update"],
  props: {
    platformInfo: { type: Object, default: null },
    platform: { type: String },
    status: { type: String },
    serverName: { type: String },
    botId: { type: String, default: "" },
    avatar: { type: String, default: "" },
    logo: { type: String, default: "" },
    number: { type: String, default: "" },
    secretary: { type: String, default: "" },
    online: { type: String, default: "" },
    showBtns: { type: Boolean, default: true, required: false },
    showDialog: { type: Boolean, default: true, required: false },
    statusSmall: { type: Boolean, default: false },
    packageSupport: { type: Boolean, default: false },
    size: { type: String, default: "30px" },
    restrictedInfo: { type: Object, default: null },
  },
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const store = useStore();
    const route = useRoute();

    const platformsHolder = platforms();
    const { platformsList } = storeToRefs(platformsHolder);

    const dialogVisible = ref(false);
    const closeTooltip = ref(false);
    const details_dialog = ref(false);
    const shot_modal = ref(false);

    const data = ref({
      platform: props.platform,
      status: props.status,
      botId: props.botId,
      avatar: props.avatar,
      logo: props.logo,
      number: props.number,
      secretary: props.secretary,
      online: props.online,
      showBtns: props.showBtns,
      statusSmall: props.statusSmall,
      size: props.size,
      server_name : props.serverName
    });

    watch(shot_modal, () => {
      shot.value = {};
    });

    // const server_name = ref(props.serverName);
    watch(props, () => {
      data.value.platform = props.platform;
      data.value.status = props.status;
      data.value.botId = props.botId;
      data.value.avatar = props.avatar;
      data.value.logo = props.logo;
      data.value.number = props.number;
      data.value.secretary = props.secretary;
      data.value.online = props.online;
      data.value.showBtns = props.showBtns;
      data.value.statusSmall = props.statusSmall;
      data.value.size = props.size;
      data.value.server_name = props.serverName;
    });

    const submitLogOutButton = ref<HTMLButtonElement | null>(null);

    const powerOffBot = () => {
      if (submitLogOutButton.value) {
        // eslint-disable-next-line
        submitLogOutButton.value!.disabled = true;
        // Activate indicator
        submitLogOutButton.value.setAttribute("data-kt-indicator", "on");
      }

      if (userType.value === "admin") {
        adminPowerOff(props.botId, props.platform);
      } else {
        powerOff(props.botId, props.platform);
      }

      // powerOff(props.botId, props.platform);
      Swal.fire({
        text: "لطفا تا زمان قطع ارتباط پلتفرم، هیچ فعالیتی انجام ندهید.",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "متوجه شدم !",
        customClass: {
          confirmButton: "btn btn-warning",
        },
      });
    };

    const shot = ref({});
    const shot_loading = ref(true);
    const get_shot = (data) => {
      console.log(data);
      shot_loading.value = true;
      // cancel_btn_disabled.value = true;
      ApiService.post(
        `/admin/bot/take-screenshot`,
        { platform: data.platform, bot_id: data.botId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          console.log(res);
          shot_loading.value = false;
          shot.value = res.data;
        })
        .catch((e) => {
          console.log(e);
          shot_loading.value = false;
        });
    };
    const allBotsHolder = AllBots();
    const { fetchBotList, powerOff, adminPowerOff } = allBotsHolder;
    // const connectToSocket = () => {
    //   if (JwtService.getUserId()) {
    //     window.Echo.private("user." + JwtService.getUserId()).listen(
    //       ".bot_deactivated",
    //       () => {
    //         fetchBotList();
    //         if (submitLogOutButton.value) {
    //           //Deactivate indicator
    //           submitLogOutButton.value?.removeAttribute("data-kt-indicator");
    //           // eslint-disable-next-line
    //           submitLogOutButton.value!.disabled = false;
    //         }
    //         emit("update", true);
    //       }
    //     );
    //   }
    // };

    // pulling
    const pulling_data = ref();
    // const pulling = () => {
    //   if (
    //     route.name !== "bot-connections" &&
    //     route.name !== "connect-to-platform"
    //   ) {
    //     console.log("server info pulling");

    //     ApiService.get("/whats-up")
    //       .then((data) => {
    //         console.log(data.data.data);
    //         let res = data.data.data.operations;

    //         for (let i = 0; i < res.length; i++) {
    //           if (res[i].type === "bot_deactivated") {
    //             fetchBotList();
    //             if (submitLogOutButton.value) {
    //               //Deactivate indicator
    //               submitLogOutButton.value?.removeAttribute(
    //                 "data-kt-indicator"
    //               );
    //               // eslint-disable-next-line
    //               submitLogOutButton.value!.disabled = false;
    //             }
    //             emit("update", true);
    //           }
    //         }
    //       })
    //       .catch(({ response }) => {
    //         console.log(response);
    //       });
    //   }
    // };

    const permission = ref();
    const userType = ref();
   const prevPer = ref();
    
    onMounted(() => {
      // let user: any = localStorage.getItem("user_info");
      // permission.value = JSON.parse(user).permissions;
      // userType.value = JSON.parse(user).type;

        setTimeout(() => {
        let per: any = localStorage.getItem("permissions");
        permission.value = JSON.parse(per);
        let user: any = localStorage.getItem("user_info");
        userType.value = JSON.parse(user).type;

        let prev_user: any = localStorage.getItem("previous_user_info") || {};
        prevPer.value = JSON.parse(prev_user).permissions;
      }, 3000);

      

      pulling_data.value = localStorage.getItem("user_info");
      // if (JSON.parse(pulling_data.value).polling_mode === true) {
      //   setInterval(() => {
      //     pulling();
      //   }, 8000);
      // } else {
      //   connectToSocket();
      // }
      // if (JSON.parse(pulling_data.value).polling_mode === true) {
      //   connectToSocket();
      // }
    });

    /*** start change avatar ***/
    const NewAvatar = ref();
    const submitButtonChangeAvatar = ref<HTMLElement | null>(null);
    const imgUrl = ref("/media/avatars/blank.png" as any);

    const OnChangeAvatar = () => {
      let reader = new FileReader();
      if (NewAvatar.value.files[0]) {
        reader.onload = (e) => {
          imgUrl.value = e.target?.result;
        };
        reader.readAsDataURL(NewAvatar.value.files[0]);
      }
      submitChangeAvatar();
    };

    const sending = ref(false);
    const submitChangeAvatar = () => {
      sending.value = true;
      submitButtonChangeAvatar.value?.setAttribute("data-kt-indicator", "on");
      const formData = new FormData();
      if (NewAvatar.value.files[0] !== undefined) {
        formData.append("avatar", NewAvatar.value.files[0]);
      }
      ApiService.post(
        `bot/${data.value.botId}/${data.value.platform}/change-avatar`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then(() => {
          dialogVisible.value = false;
          emit("update");
          ElNotification({
            title: translate("success"),
            message: translate("The profile has been updated"),
            type: "success",
          });
        })
        .catch((e) => {
          store.commit(Mutations.SET_ERROR, e.response?.data);
        })
        .finally(() => {
          sending.value = false;
          submitButtonChangeAvatar.value?.removeAttribute("data-kt-indicator");
        });
    };

    // const serverHolder = Servers();
    // const { serverList } = storeToRefs(serverHolder);
    // const { fetchServersList, reboot, changeBotServer } = serverHolder;

    // const handleChangeBotServer = (event, bot_id, platform) => {
    //   changeBotServer(bot_id, platform, event.target.value);
    // };

    return {
      translate,
      statusIcon,
      statusColor,
      powerOffBot,
      data,
      details_dialog,
      submitLogOutButton,
      closeTooltip,
      dialogVisible,

      submitButtonChangeAvatar,
      submitChangeAvatar,
      OnChangeAvatar,
      imgUrl,
      NewAvatar,
      // server_name,
      sending,
      platformsList,
      prevPer,
      // serverList,
      // handleChangeBotServer,
      // reboot,

      userType,
      permission,
      shot_modal,
      get_shot,
      shot,
      shot_loading,
    };
  },
});
