const emoji = {
  "funny and happy": [
    "😀",
    "😃",
    "😄",
    "😁",
    "😆",
    "😅",
    "😂",
    "🤣",
    "😊",
    "😇",
    "🙂",
    "🙃",
    "😉",
    "😌",
    "😍",
    "🥰",
    "😘",
    "😗",
    "😙",
    "😚",
    "😋",
    "😛",
    "😝",
    "😜",
    "🤪",
    "🤨",
    "🧐",
    "🤓",
    "😎",
    "🤩",
    "🥳",
    "😏",
    "😒",
    "😞",
    "😔",
    "😟",
    "😕",
    "🙁",
    "☹️",
    "😣",
    "😖",
    "😫",
    "😩",
    "🥺",
    "😢",
    "😭",
    "😤",
    "😠",
    "😡",
    "🤬",
    "🤯",
    "😳",
    "🥵",
    "🥶",
    "😱",
    "😨",
    "😰",
    "😥",
    "😓",
    "🤗",
    "🤔",
    "🤭",
    "🤫",
    "🤥",
    "😶",
    "😐",
    "😑",
    "😬",
    "🙄",
    "😯",
    "😦",
    "😧",
    "😮",
    "😲",
    "🥱",
    "😴",
    "🤤",
    "😪",
    "😵",
    "🤐",
    "🥴",
    "🤢",
    "🤮",
    "🤧",
    "😷",
    "🤒",
    "🤕",
    "🤑",
    "🤠",
    "😈",
    "👿",
    "👹",
    "👺",
    "🤡",
    "💩",
    "👻",
    "💀",
    "☠️",
    "👽",
    "👾",
    "🤖",
    "🎃",
    "😺",
    "😸",
    "😹",
    "😻",
    "😼",
    "😽",
    "🙀",
    "😿",
    "😾",
  ],
  "Rainbow Bubble": [
    "🌈",
    "🌦️",
    "🌧️",
    "⛈️",
    "🌩️",
    "🌨️",
    "❄️",
    "☃️",
    "⛄",
    "🌬️",
    "💨",
    "🌪️",
    "🌫️",
    "🌊",
  ],
  "Animals and Nature": [
    "🐶",
    "🐱",
    "🐭",
    "🐹",
    "🐰",
    "🦊",
    "🐻",
    "🐼",
    "🐨",
    "🐯",
    "🦁",
    "🐮",
    "🐷",
    "🐽",
    "🐸",
    "🐵",
    "🙈",
    "🙉",
    "🙊",
    "🐒",
    "🐔",
    "🐧",
    "🐦",
    "🐤",
    "🐣",
    "🐥",
    "🦆",
    "🦅",
    "🦉",
    "🦇",
    "🐺",
    "🐗",
    "🐴",
    "🦄",
    "🐝",
    "🐛",
    "🦋",
    "🐌",
    "🐚",
    "🐞",
    "🐜",
    "🦟",
    "🦗",
    "🕷️",
    "🕸️",
    "🦂",
    "🐢",
    "🐍",
    "🦎",
    "🦖",
    "🦕",
    "🐙",
    "🦑",
    "🦐",
    "🦞",
    "🦀",
    "🐡",
    "🐠",
    "🐟",
    "🐬",
    "🐳",
    "🐋",
    "🦈",
    "🐊",
    "🐅",
    "🐆",
    "🦓",
    "🦍",
    "🦧",
    "🦣",
    "🐘",
    "🦛",
    "🦏",
    "🐪",
    "🐫",
    "🦒",
    "🦘",
    "🦬",
    "🐃",
    "🐂",
    "🐄",
    "🐎",
    "🐖",
    "🐏",
    "🐑",
    "🦙",
    "🐐",
    "🦌",
    "🐕",
    "🐩",
    "🐈",
    "🐓",
    "🦃",
    "🦚",
    "🦜",
    "🦢",
    "🦩",
    "🕊️",
    "🐇",
    "🦝",
    "🦨",
    "🦡",
    "🦫",
    "🦦",
    "🦥",
    "🐁",
    "🐀",
    "🐿️",
    "🦔",
    "🐾",
    "🐉",
    "🐲",
    "🌵",
    "🎄",
    "🌲",
    "🌳",
    "🌴",
    "🌱",
    "🌿",
    "☘️",
    "🍀",
    "🎍",
    "🎋",
    "🍃",
    "🍂",
    "🍁",
    "🍄",
    "🌾",
    "💐",
    "🌷",
    "🌹",
    "🥀",
    "🌺",
    "🌸",
    "🌼",
    "🌻",
    "🌞",
    "🌝",
    "🌛",
    "🌜",
    "🌚",
    "🌕",
    "🌖",
    "🌗",
    "🌘",
    "🌑",
    "🌒",
    "🌓",
    "🌔",
    "🌙",
    "🌎",
    "🌍",
    "🌏",
    "🪐",
    "💫",
    "⭐",
    "🌟",
    "✨",
    "⚡",
    "🔥",
    "💥",
    "☄️",
    "☀️",
    "🌤️",
    "⛅",
    "🌥️",
    "☁️",
    "🌦️",
    "🌧️",
    "⛈️",
    "🌩️",
    "🌨️",
    "❄️",
    "☃️",
    "⛄",
    "🌬️",
    "💨",
    "🌪️",
    "🌫️",
    "🌊",
  ],
  "Food products": [
    "🍏",
    "🍎",
    "🍐",
    "🍊",
    "🍋",
    "🍌",
    "🍉",
    "🍇",
    "🍓",
    "🫐",
    "🍈",
    "🍒",
    "🍑",
    "🍍",
    "🥥",
    "🥝",
    "🍅",
    "🍆",
    "🥑",
    "🥦",
    "🥬",
    "🥒",
    "🌶️",
    "🌽",
    "🥕",
    "🫑",
    "🥔",
    "🍠",
    "🥐",
    "🥯",
    "🍞",
    "🥖",
    "🥨",
    "🧀",
    "🍖",
    "🍗",
    "🥩",
    "🥓",
    "🍔",
    "🍟",
    "🍕",
    "🌭",
    "🥪",
    "🌮",
    "🌯",
    "🫔",
    "🥙",
    "🧆",
    "🥚",
    "🍳",
    "🥘",
    "🍲",
    "🫕",
    "🥣",
    "🥗",
    "🍿",
    "🧈",
    "🧂",
    "🥫",
    "🍱",
    "🍘",
    "🍙",
    "🍚",
    "🍛",
    "🍜",
    "🍝",
    "🍠",
    "🍢",
    "🍣",
    "🍤",
    "🍥",
    "🥮",
    "🍡",
    "🥟",
    "🥠",
    "🥡",
    "🦪",
    "🍦",
    "🍧",
    "🍨",
    "🍩",
    "🍪",
    "🎂",
    "🍰",
    "🧁",
    "🥧",
    "🍫",
    "🍬",
    "🍭",
    "🍮",
    "🍯",
    "🍼",
    "🥛",
    "☕",
    "🍵",
    "🍶",
    "🍾",
    "🍷",
    "🍸",
    "🍹",
    "🍺",
    "🍻",
    "🥂",
    "🥃",
    "🥤",
    "🧃",
    "🧉",
    "🧊",
  ],
  "Activities and Sports": [
    "🎃",
    "🎄",
    "🎆",
    "🎇",
    "🧨",
    "✨",
    "🎈",
    "🎉",
    "🎊",
    "🎋",
    "🎍",
    "🎎",
    "🎏",
    "🎐",
    "🎑",
    "🧧",
    "🎀",
    "🎁",
    "🎗️",
    "🎟️",
    "🎫",
    "🎖️",
    "🏆",
    "🏅",
    "🥇",
    "🥈",
    "🥉",
    "⚽",
    "⚾",
    "🥎",
    "🏀",
    "🏐",
    "🏈",
    "🏉",
    "🎾",
    "🥏",
    "🎳",
    "🏏",
    "🏑",
    "🏒",
    "🥍",
    "🏓",
    "🏸",
    "🥊",
    "🥋",
    "🥅",
    "⛳",
    "⛸️",
    "🎣",
    "🤿",
    "🎽",
    "🛹",
    "🥌",
    "🎿",
    "🛷",
    "🪂",
    "🏹",
    "🎯",
    "🪀",
    "🪁",
    "🎱",
    "🔮",
    "🪄",
    "🧩",
    "🧸",
    "🎮",
    "🕹️",
    "🎰",
    "🎲",
    "🧳",
    "🛡️",
    "🪖",
    "🧭",
    "🧱",
    "🔧",
    "🔩",
    "⚙️",
    "🧲",
    "🪓",
    "🔫",
    "💣",
    "🔪",
    "🗡️",
    "⚔️",
    "🛡️",
    "🚬",
    "⚰️",
    "⚱️",
    "🏺",
    "🔮",
    "📿",
    "💈",
    "⚗️",
    "🔭",
    "🔬",
    "🕳️",
    "💊",
    "💉",
    "🌡️",
    "🚽",
    "🚰",
    "🚿",
    "🛁",
    "🛀",
    "🧴",
    "🧷",
    "🧹",
    "🧺",
    "🧻",
    "🪠",
    "🪣",
    "🪥",
    "🧼",
    "🧽",
    "🧯",
    "🛒",
    "🚬",
    "⚰️",
    "⚱️",
    "🏺",
    "🔮",
    "📿",
    "💈",
    "⚗️",
    "🔭",
    "🔬",
    "🕳️",
    "💊",
    "💉",
    "🌡️",
    "🚽",
    "🚰",
    "🚿",
    "🛁",
    "🛀",
    "🧴",
    "🧷",
    "🧹",
    "🧺",
    "🧻",
    "🪠",
    "🪣",
    "🪥",
    "🧼",
    "🧽",
    "🧯",
    "🛒",
  ],
  "Travel and Places": [
    "🚕",
    "🚙",
    "🚌",
    "🚎",
    "🏎️",
    "🚓",
    "🚑",
    "🚒",
    "🚐",
    "🚚",
    "🚛",
    "🚜",
    "🦽",
    "🦼",
    "🛴",
    "🚲",
    "🛵",
    "🏍️",
    "🛺",
    "🚠",
    "🚡",
    "🛶",
    "⛵",
    "🛥️",
    "🚤",
    "🛳️",
    "⛴️",
    "🛥️",
    "🚀",
    "🛸",
    "🚁",
    "🚧",
    "⛽",
    "🚏",
    "🚦",
    "🚥",
    "🗺️",
    "🗿",
    "🗽",
    "🌎",
    "🌏",
    "🌐",
    "🚢",
    "🛳️",
    "⛴️",
    "🚁",
    "🛶",
    "⛵",
    "🛥️",
    "🚤",
    "🛴",
    "🚲",
    "🛵",
    "🏍️",
    "🛺",
    "🚠",
    "🚡",
    "🛰️",
    "🚀",
    "🛸",
    "🌠",
    "⛱️",
    "🏖️",
    "🏝️",
    "🌅",
    "🌄",
    "🌆",
    "🌇",
    "🌉",
    "🌌",
    "🎠",
    "🎡",
    "🎢",
    "🚂",
    "🚃",
    "🚄",
    "🚅",
    "🚆",
    "🚇",
    "🚈",
    "🚉",
    "🚊",
    "🚞",
    "🚋",
    "🚌",
    "🚍",
    "🚎",
    "🚐",
    "🚑",
    "🚒",
    "🚓",
    "🚔",
    "🚕",
    "🚖",
    "🚘",
    "🚚",
    "🚛",
    "🚜",
    "🚲",
    "🛴",
    "🛵",
    "🏍️",
    "🚔",
    "🚖",
    "🚘",
    "🚛",
    "🚚",
    "🚙",
    "🚕",
    "🛺",
    "🚜",
    "🛵",
    "🚲",
    "🛴",
    "🛹",
    "🚂",
    "🚆",
    "🚃",
    "🚄",
    "🚅",
    "🚞",
    "🚋",
    "🚉",
    "🚊",
    "🚞",
    "🛰️",
    "🚀",
    "🛸",
    "🛩️",
    "💺",
    "⛵",
    "🛶",
    "🛥️",
    "🛳️",
    "⛴️",
    "🛥️",
    "🚤",
    "⛵",
    "🛶",
    "⛴️",
    "🚁",
    "🛩️",
    "🪂",
    "💺",
    "🏔️",
    "⛰️",
    "🌋",
    "🗻",
    "🏕️",
    "🏞️",
    "🏟️",
    "🏛️",
    "🏗️",
    "🧱",
    "🏘️",
    "🏚️",
    "🏠",
    "🏡",
    "🏢",
    "🏣",
    "🏤",
    "🏥",
    "🏦",
    "🏨",
    "🏩",
    "🏪",
    "🏫",
    "🏬",
    "🏭",
    "🏯",
    "🏰",
    "💒",
    "🗼",
    "🗽",
    "⛪",
    "🕌",
    "🛕",
    "🕍",
    "⛩️",
    "🕋",
    "⛲",
    "⛺",
    "🌁",
    "🌃",
    "🏙️",
    "🌄",
    "🌅",
    "🌆",
    "🌇",
    "🌉",
    "♨️",
    "🎠",
    "🎡",
    "🎢",
    "🚂",
    "🚃",
    "🚄",
    "🚅",
    "🚆",
    "🚇",
    "🚈",
    "🚉",
    "🚊",
    "🚞",
    "🚋",
    "🚌",
    "🚍",
    "🚎",
    "🚐",
    "🚑",
    "🚒",
    "🚓",
    "🚔",
    "🚕",
    "🚖",
    "🚘",
    "🚚",
    "🚛",
    "🏍️",
    "🛺",
    "🚔",
    "🚖",
    "🚘",
    "🚛",
    "🚚",
    "🚙",
    "🚕",
    "🛺",
    "🚜",
    "🛵",
    "🚲",
    "🛴",
    "🛹",
    "🚁",
    "🛩️",
    "🛬",
    "🚀",
    "🛸",
    "⛵",
    "🛶",
    "🚤",
    "🛥️",
    "🛳️",
    "⛴️",
    "🚢",
    "💺",
    "🚁",
    "🛩️",
    "🛰️",
    "🚀",
    "🛸",
    "⛵",
    "🛶",
    "🚤",
    "🛥️",
    "🛳️",
    "⛴️",
    "🚢",
    "🛂",
    "🛃",
    "🛄",
    "🛅",
    "⚓",
    "🚧",
    "⛽",
    "🚏",
    "🚦",
    "🚥",
    "🗺️",
    "🗿",
    "🗽",
    "⛲",
    "🛣️",
    "🛤️",
    "🌍",
    "🌎",
    "🌏",
    "🌐",
    "🌑",
    "🌒",
    "🌓",
    "🌔",
    "🌕",
    "🌖",
    "🌗",
    "🌘",
    "🌙",
    "🌚",
    "🌛",
    "🌜",
    "🌝",
    "🌞",
    "🌟",
    "🌠",
    "🌡️",
    "🌩️",
    "🌪️",
    "🌫️",
    "🌬️",
    "🌀",
    "🌈",
    "🌂",
    "☂️",
    "☔",
    "⛱️",
    "🎈",
    "🎉",
    "🎊",
    "🎎",
    "🎏",
    "🎐",
    "🎑",
    "🎀",
    "🎁",
    "🎗️",
    "🎟️",
    "🎫",
    "🎗️",
    "🎟️",
    "🎫",
    "🎖️",
    "🏆",
    "🏅",
    "🥇",
    "🥈",
    "🥉",
    "⚽",
    "⚾",
    "🥎",
    "🏀",
    "🏐",
    "🏈",
    "🏉",
    "🎾",
    "🥏",
    "🎳",
    "🏏",
    "🏑",
    "🏒",
    "🥍",
    "🏓",
    "🏸",
    "🥊",
    "🥋",
    "🥅",
    "⛳",
    "⛸️",
    "🎣",
    "🤿",
    "🎽",
    "🛹",
    "🥌",
    "🎿",
    "🛷",
    "🪂",
    "🏹",
    "🎯",
    "🪀",
    "🪁",
    "🎱",
    "🔮",
    "🪄",
    "🧩",
    "🧸",
    "🎮",
    "🕹️",
    "🎰",
    "🎲",
    "🧳",
    "🛡️",
    "🪖",
    "🧭",
    "🧱",
    "🔧",
    "🔩",
    "⚙️",
    "🧲",
    "🪓",
    "🔫",
  ],
  "Signs and Symbols": [
    "💣",
    "🔪",
    "🗡️",
    "⚔️",
    "🛡️",
    "🚬",
    "⚰️",
    "⚱️",
    "🏺",
    "🔮",
    "📿",
    "💈",
    "⚗️",
    "🔭",
    "🔬",
    "🕳️",
    "💊",
    "💉",
    "🌡️",
    "🚽",
    "🚰",
    "🚿",
    "🛁",
    "🛀",
    "🧴",
    "🧷",
    "🧹",
    "🧺",
    "🧻",
    "🪠",
    "🪣",
    "🪥",
    "🧼",
    "🧽",
    "🧯",
    "🛒",
    "🚬",
    "⚰️",
    "⚱️",
    "🏺",
    "🔮",
    "📿",
    "💈",
    "⚗️",
    "🔭",
    "🔬",
    "🕳️",
    "💊",
    "💉",
    "🌡️",
    "🚽",
    "🚰",
    "🚿",
    "🛁",
    "🛀",
    "🧴",
    "🧷",
    "🧹",
    "🧺",
    "🧻",
    "🪠",
    "🪣",
    "🪥",
    "🧼",
    "🧽",
    "🧯",
    "🛒",
    "💣",
    "🔪",
    "🗡️",
    "⚔️",
    "🛡️",
    "🚬",
    "⚰️",
    "⚱️",
    "🏺",
    "🔮",
    "📿",
    "💈",
    "⚗️",
    "🔭",
    "🔬",
    "🕳️",
    "💊",
    "💉",
    "🌡️",
    "🚽",
    "🚰",
    "🚿",
    "🛁",
    "🛀",
    "🧴",
    "🧷",
    "🧹",
    "🧺",
    "🧻",
    "🪠",
    "🪣",
    "🪥",
    "🧼",
    "🧽",
    "🧯",
    "🛒",
    "🛌",
    "🛏️",
    "🚪",
    "🛎️",
    "🪑",
    "🚽",
    "🚿",
    "🛁",
    "🧴",
    "🧺",
    "🧻",
    "🚮",
    "🪣",
    "🧼",
    "🧽",
    "🧹",
    "🧯",
    "🛒",
    "🛍️",
    "🎁",
    "🎈",
    "🎏",
    "🎀",
    "🎊",
    "🎉",
    "🎎",
    "🏮",
    "🧧",
    "🎗️",
    "🎟️",
    "🎫",
    "🎖️",
    "🏆",
    "🏅",
    "🥇",
    "🥈",
    "🥉",
    "⚽",
    "⚾",
    "🥎",
    "🏀",
    "🏐",
    "🏈",
    "🏉",
    "🎾",
    "🥏",
    "🎳",
    "🏏",
    "🏑",
    "🏒",
    "🥍",
    "🏓",
    "🏸",
    "🥊",
    "🥋",
    "🥅",
    "⛳",
    "⛸️",
    "🎣",
    "🤿",
    "🎽",
    "🛹",
    "🥌",
    "🎿",
    "🛷",
    "🪂",
    "🏹",
    "🎯",
    "🪀",
    "🪁",
    "🎱",
    "🔮",
    "🪄",
    "🧩",
    "🧸",
    "🎮",
    "🕹️",
    "🎰",
    "🎲",
    "🧳",
    "🛡️",
    "🪖",
    "🧭",
    "🧱",
    "🔧",
    "🔩",
    "⚙️",
    "🧲",
    "🪓",
    "🔫",
  ],
  "Science and Space": [
    "🪐",
    "⭐",
    "🌟",
    "🌠",
    "🌌",
    "🌌",
    "🌛",
    "🌜",
    "🌚",
    "🌝",
    "🌞",
    "🌍",
    "🌎",
    "🌏",
    "🌐",
    "🌑",
    "🌒",
    "🌓",
    "🌔",
    "🌕",
    "🌖",
    "🌗",
    "🌘",
    "🌙",
    "🌚",
    "🌛",
    "🌜",
    "🌝",
    "🌞",
    "🌟",
    "🌠",
    "🌌",
    "🌆",
    "🏞️",
    "🌇",
    "🌅",
    "🌃",
    "🌉",
    "🌌",
    "🌁",
    "🌄",
    "🌆",
    "🏞️",
    "🌇",
    "🌅",
    "🌃",
    "🌉",
    "🌌",
    "🌁",
    "🌋",
    "🌌",
    "🗻",
    "🏕️",
    "🏞️",
    "🌍",
    "🌎",
    "🌏",
    "🌐",
    "🏔️",
    "⛰️",
    "🌋",
    "🗻",
    "🏕️",
    "🏞️",
    "🏟️",
    "🏛️",
    "🏗️",
    "🧱",
    "🏘️",
    "🏚️",
    "🏠",
    "🏡",
    "🏢",
    "🏣",
    "🏤",
    "🏥",
    "🏦",
    "🏨",
    "🏩",
    "🏪",
    "🏫",
    "🏬",
    "🏭",
    "🏯",
    "🏰",
    "💒",
    "🗼",
    "🗽",
    "⛪",
    "🕌",
    "🛕",
    "🕍",
    "⛩️",
    "🕋",
    "⛲",
    "⛺",
    "🌁",
    "🌃",
    "🏙️",
    "🌄",
    "🌅",
    "🌆",
    "🌇",
    "🌉",
    "♨️",
    "🎠",
    "🎡",
    "🎢",
    "🚂",
    "🚃",
    "🚄",
    "🚅",
    "🚆",
    "🚇",
    "🚈",
    "🚉",
    "🚊",
    "🚝",
    "🚞",
    "🚋",
    "🚌",
    "🚍",
    "🚎",
    "🚐",
    "🚑",
    "🚒",
    "🚓",
    "🚔",
    "🚕",
    "🚖",
    "🚗",
    "🚘",
    "🚚",
    "🚛",
    "🏍️",
    "🛺",
    "🚔",
    "🚖",
    "🚘",
    "🚛",
    "🚚",
    "🚙",
    "🚕",
    "🛺",
    "🚜",
    "🛵",
    "🚲",
    "🛴",
    "🛹",
    "🚁",
    "🛩️",
    "🚀",
    "🛸",
    "⛵",
    "🛶",
    "🚤",
    "🛥️",
    "🛳️",
    "⛴️",
    "🚢",
    "🛫",
    "💺",
    "🚁",
    "🛩️",
    "🛰️",
    "🚀",
    "🛸",
    "⛵",
    "🛶",
    "🚤",
    "🛥️",
    "🛳️",
    "⛴️",
    "🚢",
    "🛂",
    "🛃",
    "🛄",
    "🛅",
    "⚓",
    "🚧",
    "⛽",
    "🚏",
    "🚦",
    "🚥",
    "🗺️",
    "🗿",
    "🗽",
    "🗼",
    "🏰",
    "🏯",
    "🏟️",
    "🎡",
    "🎢",
    "🎠",
    "⛲",
    "⛺",
    "🌋",
    "🗻",
    "🏕️",
    "🏞️",
    "🌅",
    "🌄",
    "🌠",
    "🎇",
    "🎆",
    "🌇",
    "🌆",
    "🌃",
    "🌉",
    "🌁",
  ],
  password: [
    "🔟",
    "🔢",
    "🔣",
    "🔤",
    "🅰️",
    "🆎",
    "🅱️",
    "🆑",
    "🆒",
    "🆓",
    "ℹ️",
    "🆔",
    "Ⓜ️",
    "🆕",
    "🆖",
    "🅾️",
    "🆗",
    "🅿️",
    "🆘",
    "🆙",
    "🆚",
    "🈁",
    "🈂️",
    "🈷️",
    "🈶",
    "🈯",
    "🉐",
    "🉑",
    "➕",
    "➖",
    "✖️",
    "➗",
    "➰",
    "➿",
    "〽️",
    "✳️",
    "✴️",
    "❇️",
    "‼️",
    "⁉️",
    "❓",
    "❔",
    "❕",
    "❗",
    "〰️",
    "©️",
    "®️",
    "™️",
    "#️⃣",
    "*️⃣",
    "0️⃣",
    "1️⃣",
    "2️⃣",
    "3️⃣",
    "4️⃣",
    "5️⃣",
    "6️⃣",
    "7️⃣",
    "8️⃣",
    "9️⃣",
    "🔟",
    "🔢",
    "🔣",
    "🔤",
    "🅰️",
    "🆎",
    "🅱️",
    "🆑",
    "🆒",
    "🆓",
    "ℹ️",
    "🆔",
    "Ⓜ️",
    "🆕",
    "🆖",
    "🅾️",
    "🆗",
    "🅿️",
    "🆘",
    "🆙",
    "🆚",
    "🈁",
    "🈂️",
    "🈷️",
    "🈶",
    "🈯",
    "🉐",
    "🉑",
    "➕",
    "➖",
    "✖️",
    "➗",
    "➰",
    "➿",
    "〽️",
    "✳️",
    "✴️",
    "❇️",
    "‼️",
    "⁉️",
    "❓",
    "❔",
    "❕",
    "❗",
    "〰️",
    "©️",
    "®️",
    "™️",
    "#️⃣",
    "*️⃣",
    "0️⃣",
    "1️⃣",
    "2️⃣",
    "3️⃣",
    "4️⃣",
    "5️⃣",
    "6️⃣",
    "7️⃣",
    "8️⃣",
    "9️⃣",
  ],
};

export default emoji;
