
import { defineComponent, ref } from "vue";
import { AllBots } from "@/store/pinia/AllBots";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "kt-connect-successfully",
  components: {},
  props: {
    botId: String,
    platform: String,
    secretary_status: Boolean,
    status: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const route = useRoute();
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const allBotsHolder = AllBots();
    const { powerOff } = allBotsHolder;

    const submitLogOutButton = ref<HTMLButtonElement | null>(null);

    const powerOffBot = () => {
      if (submitLogOutButton.value) {
        // eslint-disable-next-line
        submitLogOutButton.value!.disabled = true;
        // Activate indicator
        submitLogOutButton.value.setAttribute("data-kt-indicator", "on");
      }
      powerOff(props?.botId, props?.platform);
    };

    return {
      translate,
      submitLogOutButton,
      powerOffBot,
      route,
    };
  },
});
